<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col
          sm="12"
          cols="12"
          style="background-color: #fff"
          class="ma-3 mt-4 pt-3 px-5"
        >
          <h2 class="text-uppercase mb-0">
            {{ $t("welcome_to_major_admin_dashboard") }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import store from "@/store";
import { getMajorByAccessRole } from "@schoolbase/web-client-lib";
export default {
  components: {},
  data: () => ({
    listMajors: [],
    token: store.getters.getToken,
  }),
  methods: {
    async loadMajorByAccessRole() {
      try {
        let loggedUser = store.getters.getLoggedUser;
        let role = loggedUser.payload.user.access_roles[0];
        const Major = await getMajorByAccessRole(role, this.token);
        this.listMajors = Major.payload;
        this.$store.commit("LOGGED_MAJOR", Major);
        if (Major.token) {
          this.$store.commit("LOGGED_TOKEN", Major.token);
        }
      } catch (e) {
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }
      }
    },
  },
  mounted() {
    this.loadMajorByAccessRole();
  },
  computed: {},
};
</script>
<style scoped>
@media (max-width: 576px) {
}
</style>